<template>
  <div id="Information">
    <div class="baner"></div>
    <div class="box">
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">新闻资讯</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <!-- 内容 -->
        <div class="content-three flex" style="margin-top: 80px">
          <!-- 左边新闻 -->
          <div v-for="(item, index) in contentTwo" :key="index">
            <template v-if="index < 2">
              <div class="content-three-box shous" @click="goxiang(item.id)">
                <img
                  :src="
                    'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.cover
                  "
                  class="content-three-box-img"
                />
                <div class="content-three-box-time fs14 hui999">
                  {{ item.createTime }}
                </div>
                <div class="content-three-box-name fs20 hui333 fw6 textOne">
                  {{ item.title }}
                </div>
                <div class="content-three-box-text fs16 hui666 text5">
                  {{ item.withTitle }}
                </div>
              </div>
            </template>
          </div>
          <!-- 右边新闻 -->
          <div class="content-three-boxR" v-if="contentTwo.length > 2">
            <div style="height: 50px"></div>
            <div v-for="(item, index) in contentTwo" :key="index">
              <template v-if="index > 1 && index < 5">
                <div
                  class="content-three-boxR-box shous"
                  @click="goxiang(item.id)"
                >
                  <div
                    class="
                      content-three-boxR-box-name
                      textTwo
                      fs20
                      hui333
                      fw6
                      shous
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div class="fs14 hui999" style="margin-top: 8px">
                    {{ item.createTime }}
                  </div>
                </div>
              </template>
            </div>
            <div class="flex jRight content-three-boxR-more">
              <div class="flex shous ALcenter" @click="goxinwen">
                <div class="lan499 fs16">查看更多</div>
                <img
                  src="@/assets/lanIcon.png"
                  class="content-three-boxR-more-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
import { getList } from "@/api/new";
export default {
  name: "Information",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      contentTwo: [],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      let obj = {};
      getList(obj).then((res) => {
        // console.log(res);
        this.contentTwo = res.data;
      });
    },

    goxinwen() {
      this.$router.push("/news");
    },
    goxiang(e) {
      this.$router.push({ path: "/news/details", query: { id: e } });
    },
  },
};
</script>

<style scoped lang="less">
#Information {
  .baner {
    width: 1920px;
    height: 576px;
    background-image: url(~@/assets/Information1.png);
    background-size: 100% 100%;
  }
  .box {
    width: 1200px;
    margin: 0 360px;
    .bix-li {
      width: 1200px;
      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }
      .content-three {
        width: 1200px;
        margin-bottom: 194px;
        height: 516px;

        .content-three-box {
          width: 360px;
          height: 516px;
          background: #fff;
          overflow: hidden;
          box-shadow: 5px 20px 50px 1px rgba(18, 17, 39, 0.08),
            0px -1px 13px 1px rgba(18, 17, 39, 0.08);
          border-radius: 12px 12px 12px 12px;
          .content-three-box-img {
            width: 360px;
            height: 201px;
          }
          .content-three-box-time {
            width: 312px;
            height: 32px;
            margin: 30px 24px 0;
          }
          .content-three-box-name {
            width: 312px;
            height: 52px;
            margin: 30px 24px 0;
          }

          .content-three-box-text {
            width: 312px;
            height: 145px;
            line-height: 29px;
            margin: 0 24px 0;
          }
        }
        .content-three-box:nth-child(1) {
          margin-left: 24px;
        }

        .content-three-boxR {
          width: 430px;
          height: 516px;
          margin-left: 24px;
          background: #ffffff;
          box-shadow: 5px 20px 50px 1px rgba(18, 17, 39, 0.08),
            0px -1px 13px 1px rgba(18, 17, 39, 0.08);
          border-radius: 12px 12px 12px 12px;
          .content-three-boxR-box {
            width: 401px;
            height: 118px;
            margin-left: 14px;
            border-bottom: 1px solid #f6f6f6;
            margin-bottom: 20px;
            .content-three-boxR-box-name {
              width: 383px;
              height: 58px;
              line-height: 28px;
            }
          }

          .content-three-boxR-more {
            width: 383px;
            margin-left: 14px;
            .content-three-boxR-more-icon {
              width: 6px;
              height: 10px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
</style>